:root {
  /* background: linear-gradient(180deg, #000 0%, #012030 100%); */
  --color-1: #012030;
  /* --color-2: #001b26;
    --color-3: #050a0c;
    --color-4: #033a4c;
    --blur: blur(120px); */
}

body {
  background: var(--color-1);
}

.gradient-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: var(--color-2);
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
}

.gradient-color {
  width: 50%;
  height: 50%;
  display: flex;
  border-radius: 30% 80% 75% 40% / 40% 40% 70% 50%;
  animation-name: animate;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes animate {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 60%;
    transform: scale(2) rotate(0deg) translate(10%, 10%);
  }

  100% {
    border-radius: 88% 10% 22% 58% / 73% 56% 34% 77%;
    transform: scale(2) rotate(180deg) translate(10%, -10%);
  }
}

.gradient-color:nth-child(1) {
  background-color: var(--color-1);
  animation-direction: backwards;
}

.gradient-color:nth-child(2) {
  background-color: var(--color-2);
}

.gradient-color:nth-child(3) {
  background-color: var(--color-3);
  animation-direction: backwards;
}

.gradient-color:nth-child(4) {
  background-color: var(--color-4);
}

.gradient-backdrop {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  backdrop-filter: var(--blur);
}

.gradient-colors-right {
  /* background-image: url('../../assets/images/Blurredshapes2.png'); */
  background-color: var(--color-4);
  height: 25rem;
  width: 25rem;
  background-size: cover;
  position: absolute;
  /* z-index: -1; */
  right: 0;
  filter: blur(100px);
  border-radius: 50%;
  box-shadow: 0px 0px 100px var(--color-4);
  animation: glow 7s ease-in-out infinite alternate;
}

.gradient-colors-right::before {
  background-color: var(--color-4);
  height: 25rem;
  width: 25rem;
  background-size: cover;
  /* position: absolute; */
  /* z-index: 1; */
  right: 0;
  /* filter: blur(100px); */
  /* border-radius: 50%; */
  /* box-shadow: 0px 0px 100px var(--color-1); */
  /* animation: glow 7s ease-in-out infinite alternate; */
}

.gradient-colors-left {
  /* background-image: url('../../assets/images/Blurredshapes3.png'); */
  background-color: var(--color-4);
  height: 25rem;
  width: 25rem;
  background-size: cover;
  position: absolute;
  z-index: -1;
  left: 0;
  filter: blur(100px);
  box-shadow: 0px 0px 100px var(--color-4);
  animation: glow 7s ease-in-out infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 0px 0px 100px var(--color-4);
    height: 25rem;
    width: 25rem;
  }

  25% {
    box-shadow: 50px 50px 100px var(--color-4);
    height: 27rem;
    width: 27em;
  }

  50% {
    box-shadow: 100px 100px 100px var(--color-4);
    height: 25rem;
    width: 25rem;
  }

  100% {
    box-shadow: 50px 50px 100px var(--color-4);
    height: 27rem;
    width: 27em;
  }
}
