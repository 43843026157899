/* @tailwind base;
@tailwind components;
@tailwind utilities;  */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.context-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.context-content {
  text-align: center;
  width: 50%;
}

.context-content h1 {
  color: #3f3f3f;
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.context-content p {
  color: #3f3f3f;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.context-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.richtext ul {
  list-style: disc !important;
  padding-left: 10px;
}

.richtext h1 {
  font-family: Aileron;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  padding-top: 10px;
  padding-bottom: 2px;
}
.richtext h2 {
  font-family: Aileron;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: 38px;
  padding-top: 10px;
  padding-bottom: 2px;
}
.richtext h3 {
  font-family: Aileron;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding-top: 10px;
  padding-bottom: 2px;
}
.richtext h4 {
  font-family: Lora;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-top: 10px;
  padding-bottom: 2px;
}

.richtext p {
  font-family: Lora;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
.richtext p:has(img) {
  display: flex;
  flex-wrap: wrap;
  gap: 3%;
}

/* blog page css start */
.blogImage img {
  width: auto;
  height: auto;
  max-width: 47%;
  border-radius: 16px;
  margin: 30px 0;
}
@media only screen and (max-width: 600px) {
  .blogImage img {
    width: 100%;
    height: auto;
    max-width: 100%;
    border-radius: 16px;
  }
}
/* blog page css end */

.richtext p b {
  font-family: Lora;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
}

.richtextCenter {
  text-align: center;
  display: block;
}
.richtextLeft {
  text-align: center;
  display: block;
}

/* .container:last-child {
  min-width: max-content;
}
@media (min-width: 0px) {
  .container {
    max-width: none; 
  
  }

} */
.context-content li {
  color: #3f3f3f;
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0.5rem;
  border: 1px solid #ccc;
  padding: 0.3rem;
  border-radius: 5px;
}
.bluedots {
  opacity: 0.2;
  background: url(../public/images/Blurred_shapes1.png) lightgray 0px 0px / 100%
    100% no-repeat;
}

.richtext hr {
  height: 0;
  color: inherit;
  border-top-width: 2px;
  width: 12.5rem;
  color: #9aeba2;
  padding-bottom: 20px;
  margin-top: 16px;
}
.richtext-center hr {
  margin-inline: auto !important;
}
@media only screen and (max-width: 600px) {
  .richtext hr {
    width: 9rem;
  }
}

.contentText hr {
  height: 0;
  color: inherit;
  border-top-width: 2px;
  width: 12.5rem;
  color: #9aeba2;
  padding-bottom: 20px;
  margin-top: 16px;
}
@media only screen and (max-width: 600px) {
  .contentText hr {
    width: 9rem;
  }
}
/* Hero fade and zoom animation */

.header-wrapper {
  position: relative;
  /* width: 100vw;
  height: 100vh; */
  overflow: hidden;
}

.image-slider {
  display: flex;
  position: relative;
}

.slide {
  flex: 0 0 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  transition: opacity 4s ease-in-out;
}
.slide.active {
  opacity: 1;
}
@keyframes myAnim {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}
.slide div {
  width: 100%;
  height: 120vh;
  background-size: 210vh 110vh !important;
  animation: myAnim 7s ease-in-out infinite alternate-reverse forwards;
}

@media only screen and (max-width: 600px) {
  .slide div {
    background-size: 160vh 85vh !important;
  }
}
.slidetext {
  flex: 0 0 100%;
  opacity: 0;
  transform: scale(1);
  transition: opacity 5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

/* sector code */
@media only screen and (max-width: 1040px) {
  .mobile-grid-col {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media only screen and (min-width: 1040px) {
  .md-max-width-50 {
    width: 50vw;
  }
}
.slidetext div {
  width: 100%;
  height: 120vh;
  background-size: 210vh 110vh !important;
  /* background-size: contain !important; */
}

.slidetext.active {
  opacity: 1;
  transform: scale(1);
}
.slidetext h2 {
  font-weight: 400;
}

/* sector image zoom on hoover */

.zoom-effect {
  transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
}

.zoom-effect:hover {
  transform: scale(1.1); /* Scale up the image on hover */
}

.image-container {
  position: relative; /* Ensure the container is positioned relative to allow absolute positioning of overlay */
}

.overlay {
  position: absolute; /* Position the overlay absolutely on top of the image */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Dark color overlay with transparency */
  opacity: 0; /* Initially hide the overlay */
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.image-container:hover .overlay {
  opacity: 1; /* Show the overlay on hover */
}

.image-container:hover .zoom-effect {
  transform: scale(1.1); /* Show the overlay on hover */
}
